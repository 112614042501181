<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

    <!-- Page Header Start -->
	<div class="page-header bg-section parallaxie">
        <!-- Page Header Box Start -->
        <div class="page-header-box">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Header Content Start -->
                        <div class="page-header-content">
                            <h1 class="wow fadeInUp">{{ title }}</h1>
                            <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link :to="{ name: 'home'}">home</router-link></li>
                                    <li class="breadcrumb-item"><router-link :to="{ name: 'blog'}">blog</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{ strippedHtml(title) }}...</li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Page Header Content End -->
                    </div>
                </div>
            </div>
        </div>	
        <!-- Page Header Box End -->	
	</div>
	<!-- Page Header End -->

    <!-- Page Single Post Start -->
	<div class="page-single-post">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Post Featured Image Start -->
                    <div class="post-image">
                        <figure class="image-anime reveal">
                            <img :src="lgimage" alt="">
                        </figure>
                    </div>
                    <!-- Post Featured Image Start -->

                    <!-- Post Single Content Start -->
                    <div class="post-content">
                        <!-- Post Entry Start -->
                        <div class="post-entry">
                            <div v-html="body"></div>
                            <div class="text-black">Published on {{ shortNormalFormatDate(date) }}</div>
                        </div>
                        <!-- Post Entry End -->

                        <!-- Post Tag Links Start -->
                        <div class="post-tag-links">
                            <div class="row align-items-center">
                                <div class="col-lg-8">
                                    <!-- Post Tags Start -->
                                    <div class="post-tags wow fadeInUp" data-wow-delay="0.5s" v-if="tags.length > 0">
                                        <span class="tag-links">
                                            Tags:
                                            <router-link v-for="(tag, i) in tags" :key="i" :to="{ name: 'blogtags', params: { tag: tag } }">{{ tag }}</router-link>
                                        </span>
                                    </div>
                                    <!-- Post Tags End -->
                                </div>

                                <div class="col-lg-4">
                                    <!-- Post Social Links Start -->
                                    <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                                            <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                                            <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>                                            
                                            <li><a href="https://www.pinterest.com/ingomu_coaching/"><i class="fa-brands fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                    <!-- Post Social Links End -->
                                </div>
                            </div>
                        </div>
                        <!-- Post Tag Links End -->
                    </div>
                    <!-- Post Single Content End -->
                </div>
            </div>
        </div>
    </div>
    <!-- Page Single Post End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: "blogpreviewv4",
    metaInfo() {
		return {
			title: this.title,
		}
	},
    components: {
        SiteHeader,
        SiteFooter
    },
    mixins: [formateDateMixin],
    data() {
        return {
            title: '',
            body: '',
            lgimage: '',
            date: '',
            category: '',
            slug: '',
            tags: [],
        }
    },
    mounted() {
        let formattedStr = this.$route.params.slug.replace(/-/g, ' ');
        formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1)
        this.name = formattedStr
        // this.$store.dispatch("getBlogItem", { slug: this.$route.params.slug });
        this.fetchBlogItem()

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        var $window = $(window);

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()
        })
    },
    computed: {
        ...mapGetters(["previewList"]),
    },
    methods: {
        fetchBlogItem() {
            this.$store.dispatch("getBlogPreview", { slug: this.$route.params.slug });
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 3).join(" ")
        },
    },
    watch: {
        previewList(newValue) {
        newValue.forEach(element => {
            this.title = element.title
            this.body = element.body
            this.date = element.date
            this.tags = element.tags
            this.slug = element.slug
            this.category = element.category
            if (element.lgimage != null && element.lgimage != "") {
                this.lgimage = unescape(element.lgimage.substring(0, element.lgimage.indexOf("&token=")))
            } else if (element.xlimage != null && element.xlimage != "") {
                this.lgimage = unescape(element.xlimage.substring(0, element.xlimage.indexOf("&token=")))
            } else if (element.mdimage != null && element.mdimage != "") {
                this.lgimage = unescape(element.mdimage.substring(0, element.mdimage.indexOf("&token=")))
            } else if (element.smimage != null && element.smimage != "") {
                this.lgimage = unescape(element.smimage.substring(0, element.smimage.indexOf("&token=")))
            }
        })
    },
        '$route': {
        handler: 'fetchBlogItem',
        immediate: true
      }
    }

}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog.png?v1);
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>